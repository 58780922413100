body {
    background-color: #fff;
}
.albums {
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 7em;
    @media (max-width: 37.5em) {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .albumcontainer {
        display: flex;
        @media (max-width: 37.5em) {
            flex-direction: column;
            margin-top: 1rem;
        }
        background-color: white;
        min-height: 100%;
        margin: 3.2rem 0 1rem 0;
        h1,
        ul li,
        p {
            color: #222;
        }
        .albumleft {
            flex-basis: 20%;
            margin-right: 3rem;
            @media (max-width: 37.5em) {
                img {
                    width: 100%;
                }
                margin-right: 0;
                margin-bottom: 0.5rem;
            }
        }
        .albumright {
            display: flex;
            flex-direction: column;

            .flex-container-1 {
                display: flex; /* establish flex container */
                flex-direction: row; /* flex items will align horizontally */
                // justify-content: center;          /* align flex items horizontally */
                align-items: center; /* align flex items vertically */

                .flex-item {
                    margin-right: 1rem; /* a bit of space between centered flex items */
                }
            }

            .flex-container-2 {
                height: 100%;
                display: flex;
                flex-direction: column; /* flex items (in second column) will stack vertically */
                justify-content: space-between; /* align flex items vertically */

                ul {
                    display: flex;
                    font-size: 1.5rem;
                }

                ul li {
                    margin-right: 0.8rem;
                }
            }
            .ph-copy {
                font-size: 1.4rem;
                margin-top: 2rem;
            }
        }
        ul li {
            list-style: none;
        }
        a,
        a:hover,
        a:visited {
            color: blue;
            text-decoration: none;
        }
        h1 {
            // background-image: url("../../../wwwroot/Images/bill-thumb.jpg");
            background-repeat: no-repeat;
        }
    }

    .fj-gallery {
        position: relative;
        overflow: hidden;
    }
    .fj-gallery::after {
        content: "";
        display: block;
        clear: both;
    }
    .fj-gallery .fj-gallery-item {
        float: left;
        top: 0;
        left: 0;
    }
    .fj-gallery .fj-gallery-item > img {
        display: block;
        width: 100%;
        height: auto;
    }

    .searchlayout {
        display: flex;
        justify-content: center;
        margin: 3em;
        h1 {
            color: #222;
        }
    }

    #loadingDiv {
        h2 {
            display: none;
            color: #222;
        }
    }
}

// static page layout
.stlayout {
    margin-top: 3.2em;

    padding-bottom: 7em;
    @media (max-width: 64em) {
        padding-bottom: 0;
    }

    font-size: 14px;
    color: #222;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (max-width: 64em) {
        flex-direction: row;
        flex-wrap: wrap;
        margin: 1rem 1rem;
    }
    h1,
    h2,
    p,
    ul {
        width: 800px;
        @media (max-width: 64em) {
            width: 100%;
        }
    }
    h1 {
        margin-bottom: 2.4rem;
    }
    h2 {
        margin-bottom: 1.2rem;
    }
    p {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    ul {
        margin-left: 3rem;
    }

    #map {
        height: 400px;
        /* The height is 400 pixels */
        width: 800px;
        /* The width is the width of the container */
    }

    .infoDiv {
        display: flex;
        h2 {
            width: 50%;
            size: 1em;
            text-align: center;
            align-self: center;
        }
        a,
        .infoDiv a:active {
            color: #222;
        }
        a:hover {
            color: red;
        }
    }

    .aboutimgcontainer,
    .aboutimgcontainer2,
    .aboutimgcontainer3,
    .servicescontainer,
    .servicestable,
    .servicestable2 {
        display: flex;
        flex-wrap: wrap;
        gap: 1.6rem;
        margin-top: 1rem;
        margin-bottom: 2.4rem;
        width: 800px;
        @media (max-width: 64em) {
            width: auto;
        }

        .aboutbox,
        .potraitbox,
        .potraitboxwide {
            // width:250px;
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: calc(33% - 1rem);
            @media (max-width: 500px) {
                width: auto;
                flex-basis: auto;
            }
            img {
                width: 100%;
            }

            // body {
            //     font: normal 18px/1.5 "Fira Sans", "Helvetica Neue", sans-serif;
            //     background: #ffffff;
            //     color: #fff;
            //     padding: 50px 0;
            //   }

            .abcontainer {
                //width: 80%;
                // max-width: 1200px;
                margin: 0 auto;
                padding: 50px 0;

                label {
                    color: #222;
                }

                input,
                textarea {
                    background-color: #fff;
                    color: #000;
                    border: 2px solid #222;
                    font-size: 1em;
                }

                input::placeholder,
                textarea::placeholder {
                    color: #000;
                }

                @media (max-width: 37.5em) {
                    width: 80%;
                }
            }

            .abcontainer * {
                box-sizing: border-box;
            }

            .flex-outer,
            .flex-inner {
                list-style-type: none;
                padding: 0;
            }

            .flex-outer {
                max-width: 800px;
                margin: 0 auto;
            }

            .flex-outer li,
            .flex-inner {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            .flex-inner {
                padding: 0 8px;
                justify-content: space-between;
            }

            .flex-outer > li:not(:last-child) {
                margin-bottom: 20px;
            }

            .flex-outer li label,
            .flex-outer li p {
                padding: 8px;
                font-weight: 300;
                letter-spacing: 0.09em;
                text-transform: uppercase;
            }

            .flex-outer > li > label,
            .flex-outer li p {
                flex: 1 0 120px;
                max-width: 220px;
            }

            .flex-outer > li > label + *,
            .flex-inner {
                flex: 1 0 220px;
            }

            .flex-outer li p {
                margin: 0;
            }

            .flex-outer li input:not([type="checkbox"]),
            .flex-outer li textarea {
                padding: 15px;
                // border: none;
            }

            .flex-outer li button {
                margin-left: auto;
                padding: 8px 16px;
                border: none;
                background: #333;
                color: #f2f2f2;
                text-transform: uppercase;
                letter-spacing: 0.09em;
                border-radius: 2px;
            }

            .flex-inner li {
                width: 100px;
            }
        }
        .potraitbox {
            flex-basis: calc(16.6% - 1rem);
            //border: solid 1px black;
            @media (max-width: 500px) {
                // width:auto;
                flex-basis: calc(25%);
            }
        }
        .potraitboxwide {
            flex-basis: calc(33% - 1.3rem);
            @media (max-width: 500px) {
                width: 100%;
                flex-basis: auto;
            }
        }
        .publishbox {
            width: 100%; // Initially, all images take full width in mobile view

            @media (min-width: 64em) { // Desktop view
              width: auto; // Adjust width for desktop view to take up half the container width
            }
        
            img {
              width: 100%;
              height: auto;
            }
        }
        .publishbox:nth-child(n + 2) { // Select all .aboutbox elements except the first one
            @media (min-width: 64em) { // Desktop view
              width: calc(50% - 0.8rem); // Adjust width for desktop view to take up half the container width
            }
          }
        
    }

    .aboutimgcontainer2 {
        gap: 0.8rem;
    }

    .aboutimgcontainer3 {
        .potraitboxwide {
            align-self: center;
        }
        p {
            flex-basis: calc(50% - 1rem);
            @media (max-width: 500px) {
                width: 100%;
                flex-basis: auto;
            }
        }
    }
    .cona,
    .derma,
    .cmargins {
        a,
        a:hover,
        a:visited {
            color: red;
        }
    }
    .servicescontainer,
    .servicestable,
    .servicestable2 {
        //width:700px;
        @media (max-width: 700px) {
            width: 100%;
        }
        ul,
        ul > li {
            border: 0.2px solid #000;
        }
        ul {
            display: flex;
            flex-flow: row wrap;
            list-style: none;
            padding: 0;
            margin: 0 auto;
        }
        ul > li {
            display: flex;
            justify-content: flex-start;
            flex-basis: calc(50%);
            box-sizing: border-box;
            margin: 0;
            padding: 4px 7px;
        }
        li.sleft {
            justify-content: center;
            font-weight: bold;
        }
    }
    .servicestable,
    .servicestable2 {
        width: 100%;
        ul > li {
            flex-basis: calc(25%);
            flex-direction: column;
            justify-content: center;
        }
        span {
            text-align: center; /* Center the text within each span */
        }
        li.start {
            flex-direction: row;
            justify-content: flex-start;
        }
    }
    .servicestable2 {
        ul > li {
            flex-basis: calc(20%);
        }
    }
}
